<template>
  <NLayoutHeader>
    <NSpace class="bg-sky-500 items-center p-4 text-white" justify="space-between">
      <NSpace class="items-center">
        <NuxtLink to="/">
          <NImage height="30" src="logo.png" width="100" />
        </NuxtLink>
        <NButtonGroup>
          <NuxtLink to="/project">
            <NButton block type="primary">Project</NButton>
          </NuxtLink>
        </NButtonGroup>
        <NuxtLink to="/admin">
          <NButton block type="primary">Admin</NButton>
        </NuxtLink>
      </NSpace>
      <NSpace class="items-center">
        <NSwitch v-model:value="isDark" />
        <NDropdown :options="options">
          <NAvatar size="small" src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg" />
        </NDropdown>
      </NSpace>
    </NSpace>
  </NLayoutHeader>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components';
import { useDark } from '@vueuse/core';
import { NAvatar, NButton, NButtonGroup, NDropdown, NImage, NLayoutHeader, NSpace, NSwitch } from 'naive-ui';

const isDark = useDark();
const options = [
  { key: 'profile', label: 'Profile' },
  { key: 'logout', label: 'Logout' },
];
</script>
